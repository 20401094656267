import React from 'react';
import SectionComponent from '../components/sections/sections';

const ServicesPage: React.FC = (): JSX.Element => {
  return (
    <SectionComponent filter='Leistungen' />
  );
};

export default ServicesPage;
